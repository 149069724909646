import React from 'react';
import { useTheme } from 'emotion-theming';

const Visibility = () => {
    const theme = useTheme();
    return (
        <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.21 14.63">
            <path
                d="M22.76 8.02c-.87-1.04-1.82-1.92-2.84-2.68l.81-.81c.33-.33.33-.87 0-1.2s-.87-.33-1.2 0l-1.06 1.06c-.72-.41-1.45-.78-2.22-1.06l.6-1.47a.85.85 0 0 0-1.57-.65l-.67 1.63c-.71-.16-1.43-.25-2.16-.3V.85a.85.85 0 1 0-1.7 0v1.7c-.73.05-1.45.13-2.16.3l-.67-1.63a.85.85 0 0 0-1.57.65l.6 1.47c-.76.28-1.5.64-2.21 1.06L3.68 3.34c-.33-.33-.87-.33-1.2 0s-.33.87 0 1.2l.81.81C2.27 6.11 1.31 6.99.44 8.03l-.45.54.45.54c2.97 3.56 6.93 5.52 11.15 5.52s8.18-1.96 11.15-5.52l.45-.54-.45-.54Zm-11.15 4.91c-3.49 0-6.79-1.54-9.37-4.37 2.58-2.82 5.88-4.37 9.37-4.37s6.79 1.54 9.37 4.37c-2.58 2.82-5.88 4.37-9.37 4.37Z"
                fill={theme.colours.primary}
                strokeWidth={0}
            />
            <path
                d="M8.08 8.56a3.53 3.53 0 1 0 7.059.001A3.53 3.53 0 0 0 8.08 8.56Zm5.36 0a1.83 1.83 0 1 1-3.662-.002 1.83 1.83 0 0 1 3.662.002Z"
                fill={theme.colours.primary}
                strokeWidth={0}
            />
        </svg>
    );
};

export default Visibility;
